*:focus-visible,
*:focus {
    outline: unset !important;
    box-shadow: unset !important;
}
*:not(.flatpickr-input)[readonly] {
    background-color: rgba(216, 216, 216, 0.5) !important;
}
body {
    background-color: rgb(20, 20, 20);
}
:root {
    --scheduleDateBackgroundColor: #de3636;
    --scheduleDateColor: #ffffff;
}
.react-select__single-value {
    color: white !important;
}
.react-select__multi-value__label {
    color: white !important;
    background-color: rgb(20, 20, 20);
}
.react-select__multi-value__remove {
    color: white !important;
    background-color: rgb(20, 20, 20);
}
.react-select__multi-value {
    background-color: transparent !important;
}
.react-select__control {
    border-color: black !important;
}
.react-select__menu {
    /* position: relative  !important; */
    z-index: 9999 !important;
}

.react-datepicker-time__caption {
    display: none !important;
}

.react-datepicker--time-only {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgb(20, 20, 20) !important;
}
.react-datepicker-time__input {
    margin-left: 0px !important;
    background-color: rgb(14 165 233) !important;
    color: white !important;
    padding: 4px !important;
    border-color: rgb(14 165 233) !important;
    /* width: 80px !important; */
}
.react-datepicker__header {
    margin-top: -1px;
    background-color: rgb(14 165 233);
    border: none !important;
    margin-bottom: 3px;
}
.react-datepicker__time-list {
    /* width: 100px !important; */
    background-color: rgb(20, 20, 20) !important;
    color: white !important;
}
.react-datepicker__time-list li:hover {
    /* background-color: rgb(20, 20, 20) !important; */
    color: black !important;
}
.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected {
    background-color: rgb(14 165 233) !important;
}
.react-datepicker__time-box:hover {
    background-color: red !important;
}
.react-datepicker__month-container {
    background-color: rgb(20, 20, 20) !important;
}
.react-datepicker__day {
    color: white !important;
}
.react-datepicker__day:hover {
    color: black !important;
}
.custom-datepicker-input {
    width: 100% !important;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.vertical-timeline-element-date {
    display: none !important;
}
@media (min-width: 1280px) {
    .vertical-timeline-element-date {
        display: block !important;
        background-color: var(--scheduleDateBackgroundColor);
        color: var(--scheduleDateColor);
        clip-path: polygon(6% 1%, 94% 0, 100% 50%, 94% 100%, 6% 100%, 0% 50%);
    }
}
.vertical-date {
    padding: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /* color : white; */
    width: auto;
    /* background-color: #DE3636; */
    clip-path: polygon(6% 1%, 94% 0, 100% 50%, 94% 100%, 6% 100%, 0% 50%);
}
.vertical-timeline-element-content {
    border: none !important;
}
.vertical-timeline-element-content {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(
        .vertical-timeline-element--left
    )
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    text-align: center !important;
}
.vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(odd):not(
        .vertical-timeline-element--left
    )
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    text-align: center !important;
}
body {
    background-color: black;
}
.custom-date {
    transition:
        background-color 0.3s,
        color 0.3s;
}
.customScheduleCard {
    margin-top: 0px !important;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
    opacity: 1 !important;
}
.vertical-timeline-element-content {
    box-shadow: none !important;
    background-color: transparent !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
